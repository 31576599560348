"use strict";
/**
 * @def BenchmarkVisualizerForTableApp
 * @isGroup true
 * 
 * @CSSify styleName : BenchmarkVisualizerForTableAppHost
 * @CSSify styleName : BenchmarkVisualizerForTableAppTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var BenchmarkVisualizerForTableAppDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent',
			nodeName : 'BenchmarkVisualizer'.toLowerCase() + '-component'/**@CSSifyStyle componentStyle : BenchmarkVisualizerForTableAppHost */
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'SimpleTextReplace',
				nodeName : 'div',
				props : [
					{text : ''}
				]
			}),
			TypeManager.createComponentDef({
				type : 'SimpleTextReplace',
				nodeName : 'div',
				props : [
					{text : ''}
				]
			})
		]
	});
	
	return moduleDef;
}

module.exports = BenchmarkVisualizerForTableAppDef;