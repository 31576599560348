"use strict";
/**
 * @def TableBenchmark
 * @isGroup true
 * 
 * @CSSify styleName : TableBenchmarkHost
 * @CSSify styleName : TableBenchmarkTemplate
 * @CSSifyTheme themeName : basic-light
 */


var TypeManager = require('src/core/TypeManager');

var CreateStyle = require('src/core/GenericStyleConstructor');


var componentListDef = function() {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	/*
	 * Build the schematic-def of the table
	 */
	var rowDef = TypeManager.createComponentDef({
			host : TypeManager.createComponentDef({
					type : 'CompoundComponent',
					nodeName : 'tr',
					section : 0,
					states : [
						{id : undefined},
						{selected : undefined}
					],
					props : [
						{label : undefined}
					],
					reactOnParent : [
						{
							to : 'selected',
							from : 'selected',
							map : function(value) {return this.obj.hostedInterface.getProp('_component')._key.toString() === value.toString() ? 'selected' : null;}
						}
					],
					reactOnSelf : [
						{
							to : 'id',
							cbOnly : true,
							subscribe : function(value) {this.view.getMasterNode().querySelector('span').textContent = value;}
						},
						{
							to : 'label',
							cbOnly : true,
							subscribe : function(value) {this.view.getMasterNode().querySelector('a').textContent = value;}
						}
					],
					subscribeOnChild : [
						{
							on : 'clicked_ok',
							subscribe : function(e)  {
								this.trigger('update', {remove : e.self._key}, true);
							}
						}
					]
				}),
			subSections : [
				TypeManager.createComponentDef({nodeName : 'td'}),//, attributes : [{className : 'col-md-1'}]}),
				TypeManager.createComponentDef({nodeName : 'td'}),//, attributes : [{className : 'col-md-6'}]}),
				TypeManager.createComponentDef({nodeName : 'td'}),//, attributes : [{className : 'col-md-4'}]}),
//				TypeManager.createComponentDef({nodeName : 'td'}),//, attributes : [{className : 'col-md-4'}]})
			],
			members : [
				TypeManager.createComponentDef({
					nodeName : 'span',
					section : 0
				}),
				TypeManager.createComponentDef({
					type : 'VaritextButton',
					nodeName : 'a',
					section : 1,
				}),
				TypeManager.createComponentDef({
					type :  'picto_Delete',
					nodeName : 'pre',
					section : 2
				})
			]
	}, null, 'rootOnly');
	
	var tBodyDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent',
			nodeName : 'tbody',
			attributes : [
				{className : 'table'}
			],
			props : [
				{selected : undefined}
			],
			reactOnParent : [
				{
					to : 'selected',
					from : 'selected'
				}
			]
		})
	});
	tBodyDef.getGroupHostDef().section = 0;

	var moduleDef = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
					type : 'CompoundComponent',
					nodeName : 'table'
				}),
				members : 
					[
						tBodyDef
					],
				lists : 
					[
						TypeManager.createComponentDef({
							type : 'ComponentList',
							reflectOnModel : true,
							each : [],
							template : rowDef, 
							section : 0
						})
					]
	}, null, 'rootOnly');
	
//	console.log(moduleDef);
	
	return moduleDef;
}

module.exports = componentListDef;
