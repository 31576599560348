"use strict";
/**
 * @def componentListHeader
 * @isGroup true
 * 
 */


var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');


var componentListDef = function(parentView, parent) {
	/*
	 * Abstract-def of the page header
	 */
	var moduleDef = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
					nodeName : 'section',
					attributes : [
						{id : 'list_container'}
					]
				}),
				subSections : [
					TypeManager.createComponentDef({
						nodeName : 'div',
						attributes : [
							{innerHTML : 'Formant <span style = "font-size:16px">by Kinegraphx </<span><br/><span style = "font-size:24px">A frontend framework aimed at efficiency</span><br/><span><i>"Write apps for power-users..."</i><br/>(this benchmark page is based on: <a href="https://github.com/krausest/js-framework-benchmark">krausest/js-framework-benchmark</a>)<br/><i><span style = "font-size:16px; line-height:20px;">Two implementations to test perf/ease/robustness : -nice- / -fast- runs alternatively on the "Create 1000" button<br/>"Create 10 000" recalls last run (with just lots of nodes)</span></i></span>'}
						]
					}),
					TypeManager.createComponentDef({
						nodeName : 'div'
					})
				],
				members : 
					[
						TypeManager.createDef({
							host : TypeManager.createDef({
								type : 'BenchmarkVisualizerForTableApp',
								isCompound : true,
								section : 1
							})
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{textContent : 'Create 1000'}
							],
							section : 1
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{textContent : 'Create 10 000'}
							],
							section : 1
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{textContent : 'Append 1000'}
							],
							section : 1
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{textContent : 'Update every 10th'}
							],
							section : 1
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{textContent : 'Clear'}
							],
							section : 1
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{textContent : 'Swap Rows'}
							],
							section : 1
						}),
						TypeManager.createComponentDef({
							type : 'VisibleStateComponent',
							nodeName : 'button',
							attributes : [
								{className : 'hack_alternance'},
								{textContent : 'lockOnCurrentImpl'}
							],
							section : 1
						})
					]
	}, null, 'rootOnly');
	
	return moduleDef;
}

module.exports = componentListDef;