"use strict";
/**
 * @constructor BenchmarkVisualizerForTableApp
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createBenchmarkVisualizerForTableAppHostDef = require('src/UI/packages/special/BenchmarkVisualizerForTableApp/packageComponentDefs/BenchmarkVisualizerForTableAppHostDef');
//var createBenchmarkVisualizerForTableAppSlotsDef = require('src/UI/packages/panels/BenchmarkVisualizerForTableApp/packageComponentDefs/BenchmarkVisualizerForTableAppSlotsDef');

var BenchmarkVisualizerForTableApp = function(definition, parentView, parent) {
	Components.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'BenchmarkVisualizerForTableApp';
}
BenchmarkVisualizerForTableApp.prototype = Object.create(Components.CompositorComponent.prototype);
BenchmarkVisualizerForTableApp.prototype.extendsCore = ['CompoundComponent'];
BenchmarkVisualizerForTableApp.prototype.objectType = 'BenchmarkVisualizerForTableApp';

BenchmarkVisualizerForTableApp.prototype.createDefaultDef = function() {
	return createBenchmarkVisualizerForTableAppHostDef();
}

module.exports = BenchmarkVisualizerForTableApp;