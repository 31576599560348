"use strict";
/**
 * @def TableBenchmark
 * @isGroup true
 * 
 * @CSSify styleName : TableBenchmarkHost
 * @CSSify styleName : TableBenchmarkTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */


var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var componentListDef = function() {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	/*
	 * Build the schematic-def of a row
	 */
	var rowDef = TypeManager.createComponentDef({
			host : TypeManager.createComponentDef({
					type : 'CompoundComponent',
					nodeName : 'tr',
					section : 0,
					states : [
						{id : undefined},
						{selected : undefined}
					],
					props : [
						{label : undefined}
					],
					reactOnParent : [
						{
							to : 'selected',
							from : 'selected',
							map : function(value) {return this.obj.hostedInterface.getProp('_component')._key.toString() === value.toString() ? 'selected' : null;}
						}
					]
				}),
			members : [
				TypeManager.createComponentDef({
					type : 'SimpleTextReplace',
					nodeName : 'td',
//					attributes : [
//						{className : 'col-md-1'}
//					],
					reactOnParent : [
						{
							to : 'content',
							from : 'id'
						}
					]
				}),
				TypeManager.createComponentDef({
					host : TypeManager.createComponentDef({
						type : 'CompoundComponent',
						nodeName : 'td',
//						attributes : [
//							{className : 'col-md-6'}
//						],
						props : [
							{label : undefined}
						],
						reactOnParent : [
							{
								to : 'label',
								from : 'label'
							}
						]
					}),
					members : [
						TypeManager.createComponentDef({
							type : 'VaritextButton',
							nodeName : 'a',
							reactOnParent : [
								{
									to : 'content',
									from : 'label'
								}
							]
						})
					]
				}, null, 'rootOnly'),
				TypeManager.createComponentDef({
					type : 'picto_Delete',
					nodeName : 'td',
//					attributes : [
//						{className : 'col-md-4'}
//					]
				}),
//				TypeManager.createComponentDef({nodeName : 'td', attributes : [{className : 'col-md-4'}]})
			]
	}, null, 'rootOnly');

	
	var tBodyDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent',
			nodeName : 'tbody',
			attributes : [
				{className : 'table'}
			],
			props : [
				{selected : undefined}
			],
			reactOnParent : [
				{
					to : 'selected',
					from : 'selected'
				}
			]
		})
	});
	tBodyDef.getGroupHostDef().section = 0;
	
	var moduleDef = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
					type : 'CompoundComponent',
					nodeName : 'table',
					section : 1,
					states : [
						{selected : undefined}
					],
					subscribeOnChild : [
						{
							on : 'update',
							subscribe : function(e) {
								if (this._children[0]._children[0]._children[1]._children[0]) {
//								if (typeof e.data.grandParent_key !== 'undefined')
									this.streams.selected.value = e.data.grandParent_key;
								}
								else
									this.streams.selected.value = e.data.parent_key;
							}
						}
					]
				}),
				subSections :
					[
						TypeManager.createComponentDef({
							nodeName : 'section',
							attributes : [
								{innerHTML : ' \
									<div>That benchmark relies on an initial specification by Stefan Krause, which has been actively discussed and refined by a community of framework\'s authors/implementors.<br/> \
									<br/>The -fast- implementation that runs here handles automatic data-hydration and bi-directionnal binding to the model. And despite its "lesser compliance" with the framework\'s "best practives", it behaves as specified, and is completely <i>data-driven</i>.<br/> \
									The -nice- implementation also hydrates the page by reflecting the dataset, but moreover respects the full specs of the benchmark, making complete use of the light, and nonetheless cool, reactive logic of the framework, to propagate the global and local <i>states</i> to and from targeted places.</div> \
									'}
							]
						})
					],
				members :
					[
						tBodyDef
					],
				lists : 
					[
						TypeManager.createComponentDef({
							type : 'ComponentList',
							reflectOnModel : true,
							each : [],
							template : rowDef, 
							section : 0
						})
					]
	}, null, 'rootOnly');

//	console.log(moduleDef);
	
	return moduleDef;
}

module.exports = componentListDef;