"use strict";
/**
 * @router componentListRouter 
 */

var CoreTypes = require('src/core/CoreTypes');
var rDataset = require('src/core/ReactiveDataset');
var TypeManager = require('src/core/TypeManager');
var App = require('src/core/AppIgnition');

var genericPreloadGlyphStylesDef = require('src/UI/defs/_arias&glyphsDef');

var createComponentListDef = require('src/clientDef/componentListDef'); 	// Caution : check if and which module to load (example usecase : we're testing a module as standalone)
var createcomponentListDef_fast = require('src/clientDef/componentListDef_fast');
var createComponentListHeaderDef = require('src/clientDef/componentListHeaderDef');

var classConstructor = function() {
	
	function init(containerIdOrContainerNode) {
		var glyphDef = genericPreloadGlyphStylesDef;
		
		var rows = 1000;
		
		var firstNodeListID = 0,
			ComponentListObj,
			model,
			groupDef,
			alternativeGroupDef,
			shallRunImplementationA = true,
			lockOnCurrent = false;
		
		/*
		* Let's start by instaciating a definition for the "header" view
		*/
		var headerDef = createComponentListHeaderDef();
		var benchmarkScore = 0;
		
		
		/*
		 * HERE is our View-Model : each module of the View's definition is given a ref to a Command object
		 */
			/*
			 * Create 1000 rows
			 */
		headerDef.members[1].host.command = new CoreTypes.Command(function() {
//			console.log('button[1]');
			
			if (appModel.length)
				appModel.resetLength();
			model = buildData(rows);
			
			performance.mark('benchmark');
			
			if (!lockOnCurrent) {
				if (!shallRunImplementationA) {
					appModel.defaultListDef.host.template = alternativeGroupDef.lists[0].getHostDef().template;
					shallRunImplementationA = true;
				}
				else {
					appModel.defaultListDef.host.template = groupDef.lists[0].getHostDef().template;
					shallRunImplementationA = false;
				}
			}
			appModel.pushApply(model);
			
			performance.measure('bench_measure', 'benchmark');
			benchmarkScore = performance.getEntriesByName('bench_measure')[performance.getEntriesByName('bench_measure').length - 1].duration;
			ComponentListHeaderObj._children[0]._children[0].streams.text.value = 'Last operation duration: ' + benchmarkScore.toString().slice(0, 3) + ' ms';
			ComponentListHeaderObj._children[0]._children[1].streams.text.value = 'next implementation to run: ' + (shallRunImplementationA === true ? '-nice-' : '-fast-');
			
			console.log('next implementation to run : ' + (shallRunImplementationA === true ? '-nice-' : '-fast-'));
		});
			/*
			 * Create 10 000 rows
			 */
		headerDef.members[2].host.command = new CoreTypes.Command(function() {
			performance.mark('benchmark');
			
			if (appModel.length)
				appModel.resetLength();
			model = buildData(10000);
			appModel.pushApply(model);
			
			performance.measure('bench_measure', 'benchmark');
			benchmarkScore = performance.getEntriesByName('bench_measure')[performance.getEntriesByName('bench_measure').length - 1].duration;
			ComponentListHeaderObj._children[0]._children[0].streams.text.value = 'Last operation duration: ' + benchmarkScore.toString().slice(0, 3) + ' ms';
			
			console.log('next implementation to run : ' + (shallRunImplementationA === true ? '-nice-' : '-fast-'));
		});
			/*
			 * Append 1000 rows
			 */
		headerDef.members[3].host.command = new CoreTypes.Command(function() {
			performance.mark('benchmark');
			
			model = buildData(rows);
			appModel.pushApply(model);
			
			performance.measure('bench_measure', 'benchmark');
			benchmarkScore = performance.getEntriesByName('bench_measure')[performance.getEntriesByName('bench_measure').length - 1].duration;
			ComponentListHeaderObj._children[0]._children[0].streams.text.value = 'Last operation duration: ' + benchmarkScore.toString().slice(0, 3) + ' ms';
		});
			/*
			 * Update every 10th row
			 */
		headerDef.members[4].host.command = new CoreTypes.Command(function() {
			performance.mark('benchmark');
			
			for (let i = 0, l = appModel.length; i < l; i += 10) {
				appModel[i].label += ' !!!';
			}
			
			performance.measure('bench_measure', 'benchmark');
			benchmarkScore = performance.getEntriesByName('bench_measure')[performance.getEntriesByName('bench_measure').length - 1].duration;
			ComponentListHeaderObj._children[0]._children[0].streams.text.value = 'Last operation duration: ' + benchmarkScore.toString().slice(0, 3) + ' ms';
		});
			/*
			 * Clear all rows
			 */
		headerDef.members[5].host.command = new CoreTypes.Command(function() {
			performance.mark('benchmark');
			
			appModel.resetLength();
			firstNodeListID = 0;
			ComponentListObj.streams.selected.value = undefined;
			
			performance.measure('bench_measure', 'benchmark');
			benchmarkScore = performance.getEntriesByName('bench_measure')[performance.getEntriesByName('bench_measure').length - 1].duration;
			ComponentListHeaderObj._children[0]._children[0].streams.text.value = 'Last operation duration: ' + benchmarkScore.toString().slice(0, 3) + ' ms';
		});
			/*
			 * Swap rows
			 */
		headerDef.members[6].host.command = new CoreTypes.Command(function() {
			performance.mark('benchmark');
			
			if (appModel.length > 998)
				appModel.splice(998, 0, appModel.splice(2, 1, 998));
				
			performance.measure('bench_measure', 'benchmark');
			benchmarkScore = performance.getEntriesByName('bench_measure')[performance.getEntriesByName('bench_measure').length - 1].duration;
			ComponentListHeaderObj._children[0]._children[0].streams.text.value = 'Last operation duration: ' + benchmarkScore.toString().slice(0, 3) + ' ms';
		});
		
		
		
			/*
			 * hacker button
			 */
		headerDef.members[7].host.command = new CoreTypes.Command(function() {
			lockOnCurrent = lockOnCurrent ? false : true;
			console.log((lockOnCurrent ? 'locked on ' : 'not anymore locked, and shall run ') + 'the ' + (shallRunImplementationA === true ? '-nice-' : '-fast-') + ' implementation');
		});
		
		/*
		 * BEFORE we go : we use the component's ctor, 
		 * 					pass it the definition of the View for the buttons,
		 * 					and also the DOM Id of the appropriate root node.
		 */
		var ComponentListHeaderObj = new App.componentTypes.CompoundComponent(headerDef);
		new App.DelayedDecoration(containerIdOrContainerNode, ComponentListHeaderObj);
		
		
		/*
		 * AT THE BEGINNING : Let's instanciate a definition of our "list" View 
		 */
		groupDef = createComponentListDef();
		alternativeGroupDef = createcomponentListDef_fast();
		/*
		 * AND pass it (it embeds an empty array) to the Component's ctor : it's our "App"
		 */
		ComponentListObj = new App.IgnitionFromDef(groupDef, ComponentListHeaderObj.view, ComponentListHeaderObj);
		
		/*
		 * HERE we instanciate an empty "sync-ed Dataset" : it gets a reference to the App, and to the template of our "row" View, as it shadows a lot of "reactive" magic...
		 */
		var appModel = new rDataset(
				ComponentListObj,
				ComponentListObj._children[0],
				groupDef.lists[0].getHostDef().template,
				// The spec requires us to store the selected id as a property of the model
				// that's of no use : we keep track of the selected line on the component itself
				// => selected id shall stay the same even if its key changes in the model
				// In order to have some sort of compliance with the spec, we keep track of the selected id as an attribute on the table node
				['id', 'label'],
				[]
			);
		
		function _random(max) {
		    return Math.round(Math.random() * 1000) % max;
		}
		
		function buildData(count) {
		    var adjectives = ["pretty", "large", "big", "small", "tall", "short", "long", "handsome", "plain", "quaint", "clean", "elegant", "easy", "angry", "crazy", "helpful", "mushy", "odd", "unsightly", "adorable", "important", "inexpensive", "cheap", "expensive", "fancy"];
		    var colours = ["red", "yellow", "blue", "green", "pink", "brown", "purple", "brown", "white", "black", "orange"];
		    var nouns = ["table", "chair", "house", "bbq", "desk", "car", "pony", "cookie", "sandwich", "burger", "pizza", "mouse", "keyboard"];
		    var data = [];
		    for (let i = 0; i < count; i++) {
		        data.push(appModel.newItem(firstNodeListID++, adjectives[_random(adjectives.length)] + " " + colours[_random(colours.length)] + " " + nouns[_random(nouns.length)]));
		    }
		    return data;
		}
		
		
		/*
		 * Event Delegation for "Remove" action : there is no real "internal" reference to the model
		 */
		ComponentListObj.addEventListener('update', function(e) {
			console.log(e);
			if (typeof e.data.remove !== 'undefined') {
				appModel.splice(e.data.remove, 1);
			}
		});
		
		
		/*
		* This is on old app, we can't use the new mechanism to create a app-root view
		* So let's do as we were doing at that time, just append the view to the DOM
		*/
//		document.querySelector(containerIdOrContainerNode).appendChild(
//			ComponentListHeaderObj.view.getMasterNode()
//		);
		
		
		/*
		 * LET'S GO
		 */
		
		console.log('next implementation to run : ' + (shallRunImplementationA === true ? '-nice-' : '-fast-'));
	}
	
	return {
		init : init
	}
}

classConstructor.__factory_name = 'ComponentListRouter';
module.exports = classConstructor;
